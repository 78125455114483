
.slider {
    display: flex;
    position: relative;
  
    &__container {
      display: flex;
      padding: 0 20px;
      transition: transform 150ms ease ;
      z-index: 3;
      width: 100%;
    }
  
 /*    &:not(&--open) .item:hover .show-details-button {
      opacity: 1;
    }
  
    &:not(&--open) .item:hover {
      transform: scale(1.0) !important;
    } */
  
  /*   &:not(&--open):hover .item {
      transform: translateX(-25%);
    }
  
    &:not(&--open) .item:hover ~ .item {
      transform: translateX(25%);
    } */
  }