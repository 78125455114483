.outer_circle {
    stroke-width: 3;
    stroke-dasharray: 410; 
     stroke-dashoffset: 0;
    stroke-linecap: square;
    transition: all .4s ease-out;
  }
  
  .content__button__glightbox_video:hover .outer_circle {
  stroke-dashoffset:410;
    transition: stroke .7s .4s ease-out, stroke-dashoffset .4s ease-out
  }
  
  .content__button__glightbox_video:hover 
  .inner-circle {
    fill: #BF2428;
    transition:fill .4s .3s ease-out;
    
  }
  
  .content__button__glightbox_video:hover
  .play{
      fill: white;
    transition:fill .4s .3s ease-out;
  }