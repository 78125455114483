.content {
    position: relative;
    height: 37vw;
    margin-top: -40px;
  
    &__background,
    &__background__shadow,
    
    &__area {
      position: absolute;
      top: 0;
      bottom: 0;
    }
    &__background__image{
      position: absolute;
      max-width: 62%;
      top: 0;
      bottom: 0;
      opacity: 0.25;
      
    }
    
    &__background {
      left: 0;
      right: 0;
  
      &__shadow {
        left: 0;
        background: #000;
        width: 40%;
        z-index: 1;
  
        &:before {
          content: '';
          position: absolute;
          z-index: 10;
          background-image: linear-gradient(to right,#000,transparent);
          top: 0;
          bottom: 0;
          left: 100%;
          width: 275px;
        }
      }
  
      &__image {
        right: 0;
        width: 70%;
        height: 100%;
        background-position: center 10%;
        background-size: cover;
        z-index: 1;
      }
    }
  
    &__area {
      left: 0;
      right: 0;
      height: 100%;
      z-index: 3;
  
      &__container {
        padding: 30px 70px;
        height: 100%;
        color: wheat;
      }
    }
  
    &__title {
      font-size: 45px;
      color: #fff;
      font-weight: 700;
    }
  
    &__description {
      font-size: 18px;
      color: #999;
      margin-top: 20px;
      max-width: 400px;
      min-height: 300px;
    }
    &__button {
      align-items: center;
      width: 100%;
      margin-left: 50%;
      margin-top: -20%;

      &__glightbox_video {
        background-color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-10%, -10%);
        border-color: transparent;
        }
    }
    &__close {
      color: rgb(248, 74, 74);
      width: 40px;
      height: 40px;
      background: transparent;
      outline: none;
      border: none;
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }