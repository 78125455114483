.slide-button {
    position: absolute;
    top: 0;
    border:none;
    bottom: 0;
    background-color:transparent ;
    z-index: 4;

  
    span {
      width: 35px;
      height: 35px;
      color: #ffffff;
      display: block;
      background-color: #02020269;
      border-radius: 100px;
    }
  
    &--next {
      right: 0;
  
      span {
        transform: rotateZ(-90deg);
      }
    }
  
    &--prev {
      left: 0;
  
      span {
        transform: rotateZ(90deg);
      }
    }
  }