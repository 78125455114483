.iframe1 {
    position:relative;
    display:block;
    height: 480px;
    width: 650px;
  }
#iframe1{
  width: 99% !important;
  height: 99% !important;
}
.infoVideo{
    padding-top:1.25%;
    position:relative;
}
.styleIframe {
    position:absolute;
    top:0; 
    left: 0;
    
}

iframe {
  width: 100% !important;
  height: 100% !important;
  
}
#contenedorItemVideo {
  background-color: transparent;
  color: azure;
  width: 100%;
  overflow: hidden;
}
.cursorPointer{
  cursor: pointer;

}
.card{
  width: 300px;
  background-color: transparent !important;

}
.card-width{
  width: 300px;


}
.card-video{
  border:2px solid transparent !important;


  
}

.card-video:hover{
  background-color: #242334 !important;
  border:2px solid #0d3eb9 !important;
}
.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* número de líneas para mostrar */
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@media (max-width: 650px) {
  .infoVideo{
    padding-top:0.25%;
    position:relative;
    padding-bottom: 40%;
  }
  .iframe1 {
    height: 380px;
  }
}
.img-preview{
  border:2px solid red !important;
  display: none;
}
.content-preview{


}
.img-serie{
  width:100%;
  filter: brightness(.5);
  position: relative;
}
.info-serie{
}