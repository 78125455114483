.item {
    flex: 0 0 0%;
    /* transition: transform 0ms ease 0ms; */
    margin: 0 0px;
    position: relative;
    padding:0;
    
  }
  .imagen-container {
    width: 360px; /* Ancho fijo deseado */
    max-height: 190px; /* Altura fija deseada */
    overflow: hidden; /* Recorta las imágenes que sean más grandes */
    border: 2px solid transparent;
    border-radius: 8px;
    object-fit: cover;
  
  }
  .imagen-container:hover {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 5px;
  
  }
  img {
      max-width: 100%; /* Establece un ancho máximo del 100% */
      width: 300px; /* Ancho fijo deseado */
      min-height: 200px; /* Altura automática para mantener la proporción original */
      object-fit: cover;
    }
