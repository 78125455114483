.show-details-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 300ms ease 100ms;
    background: transparent;
    border: 0;
    outline: none;
    width: 100%;
  
    span {
      display: block;
      width: 24px;
      margin: 0 auto;
      color: rgb(211, 20, 20);
    }
  }
  