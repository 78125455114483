.item{
    height: 20rem;
    min-width: auto;
    padding: 0 10px;
}
.item img{
    height: 90%;
    width: 100%;
    pointer-events: none;
}
